<template>
        <!-- <span v-if="shred === 'PENDING'" class="disabled">
            <Popper class="popperDark" arrow hover content="Pending for shred">
                <i class="fa-solid fa-trash-clock fa-lg text-grey me-1"></i>
            </Popper>
        </span>
        <span v-else-if="status === 'DRAFT'" class="isTooltipsLabel mx-1" :class="class"> -->
        <span v-if="status === 'DRAFT'" class="isTooltipsLabel me-1" :class="cls" style="padding-left: 5px; padding-right: 5px">
            <Popper class="popperDark" arrow hover :content="status">
                <i class="fa-solid fa-file fa-lg text-info"></i>
            </Popper>
        </span>

        <span v-else-if="status === 'INVITED'" class="isTooltipsLabel me-1" :class="cls" style="padding-left: 3px; padding-right: 2px">
            <Popper class="popperDark" arrow hover :content="status">
                <i class="fa-solid fa-envelope fa-lg text-success"></i>
            </Popper>
        </span>

        <span v-else-if="status === 'SIGNED'" class="isTooltipsLabel me-1" :class="cls">
            <Popper class="popperDark" arrow hover :content="status">
                <i class="fa-solid fa-signature fa-lg text-success"></i>
            </Popper>
        </span>

        <span v-else-if="status === 'ALLSIGNED'" class="isTooltipsLabel me-1" :class="cls" style="padding-left: 4px; padding-right: 3px">
            <Popper class="popperDark" arrow hover :content="status">
                <i class="fa-solid fa-check-double fa-lg text-pink"></i>
            </Popper>
        </span>

        <span v-else-if="status === 'REVISE'" class="isTooltipsLabel me-1" :class="cls" style="padding-left: 3px; padding-right: 2px">
            <Popper class="popperDark" arrow hover :content="status">
                <i class="fa-solid fa-rotate-right fa-lg text-orange"></i>
            </Popper>
        </span>

        <span v-else-if="status === 'VIEWONLY'" class="isTooltipsLabel me-1" :class="cls" style="padding-right: 3px">
            <Popper class="popperDark" arrow hover :content="status">
                <i class="fa-solid fa-eye fa-lg text-brown"></i>
            </Popper>
        </span>

</template>

<script>
import { ref, onMounted } from 'vue'
import Popper from 'vue3-popper'

export default {
    name: 'DocStatus',
    components: { Popper },
    props: [ 'status', 'class', 'shred' ],
    setup (props) {

        const cls = ref(props.class)

        onMounted(() => {
            // console.info('Tag::onMounted', JSON.stringify(tags.value))
        })

        return { cls }
    }
}
</script>

<style>

</style>